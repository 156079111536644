import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useToggle from "hooks/useToggle";
import CommissionModal from "components/CommissionModal/CommissionModal";
import classes from "./PaymentChannel.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllChannels,
  setCheckoutChannel,
} from "../../../../redux/channel";
import { fetchAllTenders, setCheckoutTender } from "redux/tenders";
import { toast } from "react-toastify";
import IconButton from "components/IconButton/IconButton";
import PaymentChannelCard from "components/PaymentChannelCard/PaymentChannelCard";
import { useMemo } from "react";
import MainPaymentChannelCard from "components/PaymentChannelCard/MainPaymentChannelCard";
import GlobalPadding from "components/Layout/globalContainer/GlobalPadding";
import { useDefaultSetting } from "hooks/useDefaultSetting";
import { ImSpinner2 } from "react-icons/im";
import CustomCheckbox from "components/CustomCheckbox/CustomCheckbox";
import { ResturantService } from "services/ResturantService";
import { toggleConfig } from "redux/app";
import { ORDER_COMPLETE_PERMISSION } from "constants/constants";
import { usePermissionHooks } from "hooks/usePermissionHooks";
import PrimaryButton from "components/PrimaryButton/PrimaryButton";
import { OrderServices } from "services/OrderServices";

import SplitBillCard from "components/PaymentChannelCard/SplitBillCard";

const PaymentChannel = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedTender, setSelectedTender] = useState({});
  const [selectedChannel, setSelectedChannel] = useState({});
  const [showModal, toggleModal] = useToggle(false);
  const { order } = useSelector((state) => state.editOrder);
  const cartState = useSelector((state) => state.cart);
  const { channels, error, isLoading } = useSelector((state) => state.channel);
  const {
    tenders,
    isLoading: tenderLoading,
    error: tenderError,
  } = useSelector((state) => state.tender);
  const { selectedOrders } = useSelector((state) => state.editOrder);
  const [dineIn, setDineIn] = useState(false);
  const { default_tender, default_channel } = useDefaultSetting();
  const [defaultLoading, setDefaultLoading] = useState({
    channelLoading: false,
    tenderLoading: false,
  });
  const [makeDefaultChannel, setMakeDefaultChannel] = useState(false);
  const [makeDefaultTender, setMakeDefaultTender] = useState(false);

  const { canView: canCompleteOrder } = usePermissionHooks(
    ORDER_COMPLETE_PERMISSION
  );
  const location = useLocation();

  const order_id = useMemo(
    () => location?.state?.order_id,
    [location?.state?.order_id]
  );

  useMemo(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useMemo(() => {
    if (cartState?.checkoutOrder.type === "dine in") {
      setDineIn(true);
    } else {
      setDineIn(false);
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [selectedOrders]);

  // Getting the tenders and channel taxes when they load from API
  const tenderTax = useMemo(() => {
    if (tenders && tenders.length && default_tender && default_tender?.name) {
      return tenders.filter((item) => item?.name === default_tender?.name)[0]
        ?.order_tender_tax;
    }
  }, [tenders, default_tender]);
  const channelTax = useMemo(() => {
    if (
      channels &&
      channels.length &&
      default_channel &&
      default_channel?.name
    ) {
      return channels.filter((item) => item?.name === default_channel?.name)[0]
        ?.order_delivery_commission;
    }
  }, [channels, default_channel]);

  useEffect(() => {
    setSelectedChannel({
      ...default_channel,
      img: default_channel?.smallimage,
      channel_tax: channelTax ?? null,
    });
    dispatch(
      setCheckoutChannel({
        checkoutChannel: {
          ...default_channel,
          img: default_channel?.smallimage,
          channel_tax: channelTax ?? null,
        },
      })
    );
    setSelectedTender({
      ...default_tender,
      tender_tax: tenderTax ?? null,
    });
    dispatch(setCheckoutTender(default_tender));
    // selectDefault();
  }, [default_channel, default_tender, dispatch, channelTax, tenderTax]);

  // Getting the channel when order type does contain is dine in
  const encodedOrderId = encodeURIComponent(order_id);

  // Getting the channel when order type does contain is dine in
  useEffect(() => {
    if (!dineIn) {
      dispatch(fetchAllChannels({ order_id: `${encodedOrderId}` }));
    }
    dispatch(fetchAllTenders({ order_id: `${encodedOrderId}` }));
  }, [dineIn, dispatch, encodedOrderId]);

  const changeTenderHandler = (newTender) => {
    console.log(selectedTender?.name === newTender?.name, newTender);

    if (selectedTender?.name === newTender?.name) {
      dispatch(setCheckoutTender(null));
      setSelectedTender({});
    } else {
      dispatch(setCheckoutTender(newTender));
      setSelectedTender(newTender);
    }
  };

  const changeChannelHandler = (newChannel) => {
    if (selectedChannel?.name === newChannel?.name) {
      dispatch(setCheckoutChannel({ checkoutChannel: null }));
      setSelectedChannel({});
    } else {
      // dispatch(setCheckoutChannel(newChannel));
      setSelectedChannel(newChannel);
    }
  };

  // const proceedCheckout = () => {
    // If tender is Split Bill, navigate to split bill screen
    // if (selectedTender?.name === "Split Bill") {
    //   navigate('/orders/checkout/split-bill', {
    //     state: {
    //       orderId: order?.id,
    //       totalAmount: order?.total_amount
    //     }
    //   });
    //   return;
    // }

    // For non-Split Bill cases, continue with existing logic
    // if (cartState?.checkoutOrder?.type !== "dine in") {
    //   toggleModal();
    //   return;
    // }
    // proceedToFinalStep();
  // };

  const proceedToFinalStep = () => {
    // Checking to see if there is a choosing channel and save it for checkout else just navigate

    if (selectedTender?.name === "Split Bill") {
      navigate("/orders/checkout/split-bill", {
        state: {
          orderId: order?.id,
          totalAmount: order?.total_amount,
        },
      });
      return;
    }

    if (selectedChannel?.name) {
      dispatch(setCheckoutChannel({ checkoutChannel: { ...selectedChannel } }));
      navigate("/orders/checkout/receipt");
    } else {
      navigate("/orders/checkout/receipt");
    }
  };

  const navigateToOrders = () => {
    navigate("/orders/all");
  };

  // dISABLE button if order type equal takeaway or delivery and user hasn't chosen channel
  const isDisabled =
    cartState?.checkoutOrder.type !== "dine in" &&
    cartState?.checkoutOrder.type !== "takeaway" &&
    !selectedChannel?.name;

  const chooseDefaultChannel = () => {
    if (!selectedChannel) {
      toast.error("Please select a channel to set as default", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      setDefaultLoading({ ...defaultLoading, channelLoading: true });
      ResturantService.chooseDefaultServiceType(
        {
          type: "delivery_channel",
          value: `${selectedChannel?.id}`,
        },
        (res) => {
          setDefaultLoading({ ...defaultLoading, channelLoading: false });
          dispatch(toggleConfig(true));
          setMakeDefaultChannel(true);
          toast.success(
            res?.response?.data?.message ||
              `${selectedChannel?.name} has been set as your default channel.`,
            {
              position: toast.POSITION.BOTTOM_RIGHT,
            }
          );
        },
        (err) => {
          setDefaultLoading({ ...defaultLoading, channelLoading: false });
          toast.error(
            err?.response?.data?.message ||
              `Unable to set ${selectedChannel?.name} as your default channel.`,
            {
              position: toast.POSITION.BOTTOM_RIGHT,
            }
          );
        }
      );
    }
  };
  const chooseDefaultTender = () => {
    if (!selectedTender) {
      toast.error("Please select a tender to set as default", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      setDefaultLoading({ ...defaultLoading, tenderLoading: true });
      ResturantService.chooseDefaultServiceType(
        {
          type: "tender",
          value: `${selectedTender?.id}`,
        },
        (res) => {
          setDefaultLoading({ ...defaultLoading, tenderLoading: false });
          dispatch(toggleConfig(true));
          setMakeDefaultTender(true);
          toast.success(
            res?.response?.data?.message ||
              `${selectedTender?.name} has been set as your default tender.`,
            {
              position: toast.POSITION.BOTTOM_RIGHT,
            }
          );
          setTimeout(() => {
            navigateToOrders();
          }, 2000);
        },
        (err) => {
          setDefaultLoading({ ...defaultLoading, tenderLoading: false });
          toast.error(
            err?.response?.data?.message ||
              `Unable to set ${selectedTender?.name} as your default tender.`,
            {
              position: toast.POSITION.BOTTOM_RIGHT,
            }
          );
        }
      );
    }
  };

  const handleOrderSave = () => {
    const payload = {
      order_id: order?.id,
      tender_id: selectedTender?.id || null,
      delivery_channel_id: selectedChannel?.id || null,
    };
    if (!selectedTender) {
      toast.error("Please select a tender.", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else {
      OrderServices.updateOrderTenderChannal(
        payload,
        (res) => {
          toast.success(
            res?.response?.data?.message || `order has been saved.`,
            {
              position: toast.POSITION.BOTTOM_RIGHT,
            }
          );
          setTimeout(() => {
            navigateToOrders();
          }, 1000);
        },
        (err) => {
          toast.error(
            err?.response?.data?.message ||
              `Unable to save order with ${selectedTender?.name}.`,
            {
              position: toast.POSITION.BOTTOM_RIGHT,
            }
          );
        }
      );
    }
  };

  useEffect(() => {
    if (!order || !channels || !tenders) return;

    const preChannel = channels.find(
      (channel) => channel.id === order.delivery_channel_id
    );
    const preTender = tenders.find((tender) => tender.id === order.tender?.id);

    if (preChannel) {
      const channelPayload = {
        id: preChannel.id,
        name: preChannel.name,
        img: preChannel.smallimage,
        channel_tax: preChannel.order_delivery_commission,
        commission_type: preChannel.commission_type,
        commission: preChannel.commission,
      };
      setSelectedChannel(channelPayload);
    }

    if (preTender) {
      const tenderPayload = {
        id: preTender.id,
        name: preTender.name,
        img: preTender.smallimage,
        tax: preTender.tax,
        tax_type: preTender.tax_type,
        tender_tax: preTender.order_tender_tax,
      };
      setSelectedTender(tenderPayload);
    }
  }, [order, channels, tenders]);

  return (
    <GlobalPadding>
      <div className="w-full h-screen">
        <CommissionModal
          showModal={showModal}
          toggleModal={toggleModal}
          selectedChannel={selectedChannel}
          order={order}
          onProceed={proceedToFinalStep}
        />
        <div className={classes.FlexBox}>
          {/* Channel and Tender */}
          <div
            className={
              !dineIn ? "w-full grid md:grid-cols-2 gap-20 px-5" : "w-full px-5"
            }
          >
            {/* Tender */}
            <div className="w-full">
              {/* Header */}
              <div className="w-full flex flex-row items-center justify-between">
                <h6 className="text-2xl lg:text-3xl font-semibold text-[#1F2937] font-Inter ">
                  Tender
                </h6>
                <p className="text-2xl lg:text-3xl font-semibold text-[#1F2937] font-Inter">
                  طرق الدفع
                </p>
              </div>

              {!tenderLoading && tenders && tenders?.length === 0 && (
                <div>
                  <p className="text-red-500 font-Inter text-3xl">
                    No Tender available now at the moment. Please contact
                    Manager to add Tender.
                  </p>
                </div>
              )}
              {/* Tenders */}
              <div
                className={
                  !dineIn
                    ? "w-full mt-4 grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 lg:gap-14 gap-10 xl:gap-5 mb-5 pt-10"
                    : "w-full mt-4 grid grid-cols-2 md:grid-cols-3 gap-5 lg:grid-cols-4 xl:grid-cols-5 mb-5 pt-10"
                }
              >
                {tenderLoading && (
                  <div>
                    <p className="text-gray-500 font-Inter text-2xl">
                      Loading Tenders...
                    </p>
                  </div>
                )}
                {!tenderLoading && !tenders && tenderError && (
                  <div className="w-full">
                    <p className="text-red-500 font-Inter text-2xl">
                      {tenderError}
                    </p>
                    <p
                      onClick={() => dispatch(fetchAllTenders())}
                      className="text-blue-500 font-semibold underline pt-8 cursor-pointer"
                    >
                      Try Again
                    </p>
                  </div>
                )}
                {!tenderLoading &&
                  tenders &&
                  tenders?.map((tender) => {
                    return (
                      <PaymentChannelCard
                        key={tender?.name}
                        name={tender?.name}
                        img={tender?.smallimage}
                        id={tender?.id}
                        tender_tax={tender?.order_tender_tax}
                        isSelected={selectedTender?.name === tender?.name}
                        onClicked={changeTenderHandler}
                        {...tender}
                      />
                    );
                  })}
              </div>
              {(!default_tender || default_tender == null) &&
                !tenderLoading &&
                !tenderError && (
                  <div className="flex flex-row items-center">
                    <CustomCheckbox
                      name="tender"
                      value="makeDefaultTender"
                      text="Set as default tender"
                      isChecked={makeDefaultTender}
                      onChanged={chooseDefaultTender}
                    />
                    {defaultLoading.tenderLoading && (
                      <ImSpinner2 className="animate-spin ml-2 text-primary" />
                    )}
                  </div>
                )}
            </div>
            {/* Channel */}
            {!dineIn && (
              <div className="w-full">
                {/* Header */}
                <div className="w-full flex flex-row items-center justify-between">
                  <h6 className="text-2xl lg:text-3xl font-semibold text-[#1F2937] font-Inter ">
                    Channel
                  </h6>
                  <p className="text-2xl lg:text-3xl font-semibold text-[#1F2937] font-Inter">
                    الوسطاء
                  </p>
                </div>
                {/* Channel list */}
                <div className="w-full grid grid-cols-2 xl:grid-cols-3 gap-14  lg:gap-5 mb-5 pt-10">
                  {isLoading && (
                    <div>
                      <p className="text-gray-500 font-Inter text-2xl">
                        Loading
                      </p>
                    </div>
                  )}
                  {!isLoading && !channels && error && (
                    <div className="w-full">
                      <p className="text-red-500 font-Inter text-2xl">
                        {error}
                      </p>
                      <p
                        onClick={() => dispatch(fetchAllChannels())}
                        className="text-blue-500 font-semibold underline pt-8 cursor-pointer"
                      >
                        Try Again
                      </p>
                    </div>
                  )}
                  {!isLoading && channels && channels?.length === 0 && (
                    <div className="xl:w-[40vw] lg:w-[30vw] w-[20vw]">
                      <p className="text-red-500 font-Inter text-3xl">
                        No channels available now at the moment. Please contact
                        Manager to add channel.
                      </p>
                    </div>
                  )}
                  {!isLoading &&
                    channels &&
                    channels.map((channel) => {
                      return (
                        <MainPaymentChannelCard
                          key={channel?.id}
                          name={channel?.name}
                          img={channel?.smallimage}
                          id={channel?.id}
                          channel_tax={channel?.order_delivery_commission}
                          commission_type={channel?.commission_type}
                          isSelected={selectedChannel?.name === channel?.name}
                          commission={channel?.commission}
                          onClicked={changeChannelHandler}
                          {...channel}
                        />
                      );
                    })}
                </div>
                {(!default_channel || default_channel == null) &&
                  !isLoading &&
                  !error && (
                    <div className="flex flex-row items-center">
                      <CustomCheckbox
                        name="channel"
                        value="makeDefaultChannel"
                        text="Set as default channel"
                        isChecked={makeDefaultChannel}
                        onChanged={chooseDefaultChannel}
                      />
                      {defaultLoading.channelLoading && (
                        <ImSpinner2 className="animate-spin ml-2 text-primary" />
                      )}
                    </div>
                  )}
              </div>
            )}
          </div>
          <h6 className="text-2xl lg:text-3xl font-semibold text-[#1F2937] font-Inter mt-5 ml-4 ">
            Other Options
          </h6>
          <div className="w-full grid grid-cols-2 xl:grid-cols-3 gap-14  lg:gap-5 mb-5 pt-10 ml-4">
            {order && (
              <SplitBillCard
                isSelected={selectedTender?.name === "Split Bill"}
                onClicked={changeTenderHandler}
                orderId={order.id}
              />
            )}
          </div>
          <div className={classes.ActionBtns}>
            <button className={classes.CancelBtn} onClick={navigateToOrders}>
              <span>Cancel</span>
              <span>يلغي</span>
            </button>
            {canCompleteOrder ? (
              <>
                <PrimaryButton
                  onClicked={handleOrderSave}
                  text="Save Order"
                  isDisabled={!selectedTender?.name || isDisabled}
                />
                <IconButton
                  icon="arrow-right"
                  iconPosition="right"
                  text="Proceed   يتابع"
                  onClicked={proceedToFinalStep}
                  disabled={!selectedTender?.name || isDisabled}
                />
              </>
            ) : (
              <IconButton
                icon="arrow-right"
                iconPosition="right"
                text="All Orders"
                onClicked={navigateToOrders}
              />
            )}
          </div>
        </div>
      </div>
    </GlobalPadding>
  );
};

export default PaymentChannel;
