import React from "react";
import DrawerCart from "components/DrawerCart/DrawerCart";
import { useSelector } from "react-redux";
import DelevirectDrawerHeader from "./component/DelevirectDrawerHeader";
import DeliverectDrawerTabHeader from "./component/DeliverectDrawerTabHeader";
import classes from "./DeliverectDrawerCart.module.scss";
import SingleListItem from "components/PrimaryDrawerCart/SingleListItem";
import DrawerCartPrimaryActionButtons from "components/DrawerCart/DrawerCartPrimaryActionButtons/DrawerCartPrimaryActionButtons";
import { useNavigate } from "react-router-dom";

const DeliverectDrawerCart = ({ toggleDrawer }) => {
  const { deliverectOrder } = useSelector((state) => state.editOrder);
  const tabs = [deliverectOrder?.order_type];
  const navigate = useNavigate();

  return (
    <>
      <DrawerCart
        id={deliverectOrder?.order_no}
        createdAt={deliverectOrder?.created_at}
        onBackgrondClick={toggleDrawer}
        status={deliverectOrder?.status}
        activeCart={deliverectOrder?.order_type}
        hideDefault
      >
        {/* Header */}
        <DelevirectDrawerHeader
          status={deliverectOrder?.status}
          order_no={deliverectOrder?.order_no}
          created_at={deliverectOrder?.created_at}
          logo={deliverectOrder?.delivery_channel?.smallimage}
          channelName={deliverectOrder?.delivery_channel?.name}
        />
        <DeliverectDrawerTabHeader tabs={tabs} />
        {/* List Item */}
        <div className={classes.PriceList}>
          {deliverectOrder &&
            deliverectOrder?.orderItems.map((item, index) => {
              return (
                <SingleListItem
                  item={item}
                  key={index}
                  id={item?.item_id}
                  index={index + 1}
                  quantity={item.quantity}
                  price={item?.item?.amount}
                  sizes={item?.item?.sizes}
                  translations={item?.item?.translations}
                  name={item?.item?.name}
                />
              );
            })}
        </div>
        {deliverectOrder?.status === "complete" && (
          <DrawerCartPrimaryActionButtons
            onProceed={() => navigate("/orders/checkout/live-order-receipt")}
            text="Void Order"
            textTwo="Print Invoice"
            icon="tick-circle"
            iconTwo="print"
            hideVoidBtn={true}
          />
        )}
      </DrawerCart>
    </>
  );
};

export default DeliverectDrawerCart;
