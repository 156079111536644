import { useMemo } from "react";
import { useSelector } from "react-redux";

export const useCartHooks = () => {
  const cartState = useSelector((state) => state.cart);
  const { selectedOrders, orderTaxes } = useSelector(
    (state) => state.editOrder
  );
  const activeTab = cartState?.activeCart;
  const activeOrder = selectedOrders.filter((item) => item.type === activeTab);

  const totalItemDiscount = useMemo(() => {
    return cartState?.cart[cartState?.activeCart].reduce(
      (acc, curr) => acc + Number(curr?.total_discount),
      0
    );
  }, [cartState?.cart, cartState?.activeCart]);

  const discount_value =
    selectedOrders && selectedOrders.length >= 1
      ? Number(selectedOrders[0]?.discount_value)
      : 0.0;

  const discount_type =
    selectedOrders && selectedOrders.length >= 1
      ? selectedOrders[0]?.discount_type
      : "";

  const showDiscount = totalItemDiscount > 0 ? true : false;
  const discountTypeReceipt =
    cartState && cartState.checkoutOrder?.discount_type
      ? cartState.checkoutOrder?.discount_type
      : "amount";
  const showDiscountReceipt = totalItemDiscount >= 0.1;
  const discountValue =
    cartState && cartState.checkoutOrder?.discount_value >= 1
      ? Number(cartState.checkoutOrder?.discount_value)
      : 0.0;
  //   Cart subtotal price
  const getActiveCartSubTotalPrice = () => {
    let subTotalPrice = 0;
    if (
      cartState &&
      cartState?.cart &&
      cartState?.cart[cartState?.activeCart]
    ) {
      for (const item of cartState?.cart[cartState?.activeCart]) {
        subTotalPrice += Number(item?.price) * Number(item?.quantity);
      }
    } else {
      subTotalPrice = 0.0;
    }

    return subTotalPrice;
  };

  const getActiveCartTax = () => {
    let vat = 0;
    if (cartState?.cart && cartState?.cart[cartState?.activeCart]) {
      for (const item of cartState?.cart[cartState?.activeCart]) {
        vat +=
          (Number(item?.vat) || Number(item?.vatvalue)) *
          Number(item?.quantity);
      }
      return vat;
    }
    // if (!isEditing) {
    //   let vat = 0;
    //   if (cartState?.cart && cartState?.cart[cartState?.activeCart]) {
    //     for (const item of cartState?.cart[cartState?.activeCart]) {
    //       vat += Number(item?.vat) * Number(item?.quantity);
    //     }
    //   }
    //   return vat;
    // } else {
    //   return Number(activeOrder[0]?.vatvalue);
    // }
  };
  const discountPrice = () => {
    let discount = 0.0;
    if (showDiscount && discount_type === "percentage") {
      const mainDiscount =
        (Number(selectedOrders[0]?.discount_value) *
          getActiveCartSubTotalPrice()) /
        100;
      discount = mainDiscount;
      return discount;
    } else if (
      showDiscount &&
      (discount_type === "fixed" || discount_type === "amount")
    ) {
      return discount_value;
    } else {
      return discount;
    }
  };
  const discountPriceReceipt = () => {
    // return Number(totalItemDiscount);

      const order = cartState?.checkoutOrder

      if (
        order.discount_value !== undefined &&
        order.discount_type !== undefined
      ) {
        if (order.discount_type === "fixed") {
          return Number(order.discount_value);
        } else if (order.discount_type === "percentage") {
          return (order.discount_value / 100) * (order.order_price + order?.vatvalue);
        }
      } else {
        let totalDiscount = 0;
        for (const item of order.orderItems) {
          if (
            item.discount_value !== undefined &&
            item.discount_type !== undefined
          ) {
            if (item.discount_type === "fixed") {
              totalDiscount += Number(item.discount_value);
            } else if (item.discount_type === "percentage") {
              totalDiscount +=
                (item.discount_value / 100) * (item.amount) * item.quantity;
            }
          }
        }
        return totalDiscount;
      }
    



    // let discount = 0.0;
    // if (showDiscountReceipt && discountTypeReceipt === "percentage") {
    //   const mainDiscount = (discountValue * getActiveCartSubTotalPrice()) / 100;
    //   discount = mainDiscount;
    //   return discount;
    // } else if (
    //   showDiscountReceipt &&
    //   (discountTypeReceipt === "fixed" || discountTypeReceipt === "amount")
    // ) {
    //   return Number(discountValue);
    // } else {
    //   return Number(discount);
    // }
  };

  /**
   * This discount is used to solve the rounding up value BOH is facing so boh sends a total discount for each item that is used for calculations instead
   * The discountPriceReceipt is also valid it will be used in other places
   *  */
  const bohDiscount = () => {
    let totalDiscount = 0;
    if (
      cartState &&
      cartState?.cart &&
      cartState?.cart[cartState?.activeCart]
    ) {
      for (const item of cartState?.cart[cartState?.activeCart]) {
        totalDiscount += Number(item?.total_discount);
      }
    } else {
      totalDiscount = 0.0;
    }

    return totalDiscount;
  };

  const activeCartTotalWithTax = () => {
    let price = 0;
    price += getActiveCartSubTotalPrice();
    price += getActiveCartTax();
    return price;
  };

  const getActiveCartTotal = () => {
    if (activeOrder[0]?.total !== null) {
      return Number(activeOrder[0]?.total);
    } else {
      return getActiveCartSubTotalPrice();
    }
  };

  const bohTax = orderTaxes[cartState?.activeCart];

  return {
    getActiveCartSubTotalPrice,
    getActiveCartTax,
    getActiveCartTotal,
    activeCartTotalWithTax,
    orderDiscount: discount_value,
    orderDiscountType: discount_type,
    showDiscount,
    discountPrice,
    showDiscountReceipt,
    discountTypeReceipt,
    discountValue,
    discountPriceReceipt,
    vatReceipt: bohTax,
    // vatReceipt: bohTax || Number(cartState?.checkoutOrder?.vatvalue),
    bohDiscount,
  };
};
