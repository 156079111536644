import Icon from "components/Icon/Icon";
import IconButton from "components/IconButton/IconButton";
import Modal from "components/Modal/Modal";
import { useGetActiveLocation } from "hooks/useGetActiveLocation";
// import { taxPercentCalculator } from "utillity/utils";
import classes from "./CommissionModal.module.scss";
import { useSelector } from "react-redux";
import { useGetTenderTaxHook } from "hooks/useGetTenderTaxHook";

const CommissionModal = ({
  showModal,
  toggleModal,
  selectedChannel,
  onProceed = () => {},
}) => {
  const { currency, taxEnabled, decimal_preference, arCurrency } =
    useGetActiveLocation();
  const { checkoutTender } = useSelector((state) => state.tender);
  const editOrder = useSelector((state) => state.editOrder);
  const checkoutOrder = editOrder?.checkoutOrder;
  // Calculating the total cart price
  const getAllSubTotalPrice = () => {
    let price = 0;
    const allItems = checkoutOrder?.orderItems;

    allItems?.forEach((el) => {
      price += +el?.amount * el?.quantity;
    });
    return price.toFixed(decimal_preference);
  };

  const { showTenderTax } = useGetTenderTaxHook({
    tax: checkoutTender?.tax,
    tax_type: checkoutTender?.tax_type,
    order_total: getAllSubTotalPrice(),
  });

  const totalCartPriceWithoutAnyCommission = getAllSubTotalPrice();

  // const getChannelFee = () => {
  //   if (selectedChannel && selectedChannel?.commission) {
  //     if (selectedChannel?.commission_type === "percentage") {
  //       return (
  //         (Number(selectedChannel?.commission) *
  //           Number(getAllSubTotalPrice())) /
  //         100
  //       );
  //     } else {
  //       return Number(selectedChannel?.commission);
  //     }
  //   }
  //   return 0;
  // };

  const totalWithoutChannelCommission =
    Number(totalCartPriceWithoutAnyCommission)
    //  + Number(editOrder.orderTaxes[activeCart]);
  // const channelFee = getChannelFee();
  // const channelFee =
  //   (Number(selectedChannel?.commission) * Number(getAllSubTotalPrice())) / 100;
  // const channelFee =
  //   (Number(selectedChannel?.commission) *
  //     (Number(getAllSubTotalPrice()) + mainVat)) /
  //   100;
  // const mainOrderPrice = Number(order?.order_price);
  // const mainVatValue = Number(order?.vatvalue);
  // const mainTotal = Number(order?.total);

  function calculateDiscount(order) {
    if(order){
      if (
        order?.discount_value !== undefined &&
        order?.discount_type !== undefined
      ) {
        if (order?.discount_type === "fixed") {
          return Number(order?.discount_value);
        } else if (order?.discount_type === "percentage") {
          return (order?.discount_value / 100) * (order?.order_price + order?.vatvalue);
        }
      } else {
        let totalDiscount = 0;
        for (const item of order?.orderItems) {
          if (
            item?.discount_value !== undefined &&
            item?.discount_type !== undefined
          ) {
            if (item?.discount_type === "fixed") {
              totalDiscount += Number(item.discount_value);
            } else if (item?.discount_type === "percentage") {
              totalDiscount +=
                (item?.discount_value / 100) * (item.amount) * item.quantity;
            }
          }
        }
        return (totalDiscount ?? 0);
      }
    }
  }

  const discount = calculateDiscount(checkoutOrder);

  const totalPayable =
    totalWithoutChannelCommission + Number(selectedChannel?.channel_tax ?? 0) + Number(checkoutTender?.tender_tax ?? 0) - discount;

  return (
    <Modal
      show={showModal}
      onClickOutside={toggleModal}
      customClasses={classes.CustomModal}
    >
      <div className={classes.ModalHeader}>
        {checkoutOrder?.type !== "dine in" &&
          selectedChannel &&
          selectedChannel?.img && (
            <div className={classes.ModalHeaderImageBox}>
              <img src={selectedChannel?.img} alt={selectedChannel.name} />
            </div>
          )}

        <button onClick={toggleModal}>
          <Icon name="cross" width={2.4} height={2.4} />
        </button>
      </div>
      <ul className={classes.ChargesInfoList}>
        <li className={classes.ChargesInfoListItem}>
          <span>Subtotal</span>
          <span>
            {currency} {totalCartPriceWithoutAnyCommission}
          </span>
        </li>
        <li className={classes.ChargesInfoListItem}>
          <span>المجموع الفرعي</span>
          <span>
            {totalCartPriceWithoutAnyCommission} {arCurrency}
          </span>
        </li>
        {/* Show tax conditionally */}
        {taxEnabled && (
          <>
            {/* <li className={classes.ChargesInfoListItem}>
              <span>Tax ({Number(tax)}٪)</span>
              <span>
                {currency}{" "}
                {Number(editOrder.orderTaxes[activeCart])?.toFixed(
                  decimal_preference
                )}
              </span>
            </li>
            <li className={classes.ChargesInfoListItem}>
              <span>الضريبة ({Number(tax)}٪)</span>
              <span>
                {Number(editOrder.orderTaxes[activeCart])?.toFixed(
                  decimal_preference
                )}{" "}
                {arCurrency}
              </span> */}
              {/* <span>{taxValue.toFixed(3)} درهم إماراتي</span> */}
            {/* </li> */}
          </>
        )}
        <hr />
        <li className={classes.ChargesInfoListItem}>
          {taxEnabled ? (
            <span>Payable amount after tax</span>
          ) : (
            <span>Payable amount without tax</span>
          )}

          <span>
            {currency}{" "}
            {totalWithoutChannelCommission?.toFixed(decimal_preference)}
          </span>
        </li>
        <li className={classes.ChargesInfoListItem}>
          <span>المبلغ المستحق قبل الضريبة</span>
          <span>
            {totalWithoutChannelCommission?.toFixed(decimal_preference)}{" "}
            {arCurrency}
          </span>
        </li>
        {selectedChannel && selectedChannel.name && (
          <>
            <li className={classes.ChargesInfoListItem}>
              {selectedChannel?.commission_type === "percentage" ? (
                <span>Channel Commission ({selectedChannel?.commission}%)</span>
              ) : (
                <span>Channel Commission ({selectedChannel?.commission})</span>
              )}

              <span>
                {(selectedChannel?.channel_tax || 0).toFixed(decimal_preference)} {arCurrency}
              </span>
            </li>
            <li className={classes.ChargesInfoListItem}>
              {selectedChannel?.commission_type === "percentage" ? (
                <span>لجنة القناة ({selectedChannel?.commission}٪)</span>
              ) : (
                <span>لجنة القناة ({selectedChannel?.commission})</span>
              )}

              <span>
                {(selectedChannel?.channel_tax || 0).toFixed(decimal_preference)} {arCurrency}
              </span>
            </li>
          </>
        )}
        {/* Tender Tax */}
        {showTenderTax && (
          <>
            <li className={classes.ChargesInfoListItem}>
              {checkoutTender?.tax_type === "percentage" ? (
                <span>Tender Commission ({checkoutTender?.tax}%)</span>
              ) : (
                <span>Tender Commission ({checkoutTender?.tax})</span>
              )}

              <span>
                {currency} {(checkoutTender?.tender_tax || 0).toFixed(decimal_preference)}
              </span>
            </li>
            <li className={classes.ChargesInfoListItem}>
              {checkoutTender?.tax_type === "percentage" ? (
                <span>عمولة العطاء({checkoutTender?.tax}٪)</span>
              ) : (
                <span> عمولة العطاء ({checkoutTender?.tax})</span>
              )}

              <span>
                {(checkoutTender?.tender_tax || 0).toFixed(decimal_preference)} {arCurrency}
              </span>
            </li>
          </>
        )}
        {discount > 0 && (
          <>
            <li className={classes.ChargesInfoListItem}>
              <span>Applied Discount</span>
              <span>
                {currency} {discount?.toFixed(decimal_preference)}
              </span>
            </li>
            <li className={classes.ChargesInfoListItem}>
              <span>الخصم المطبق</span>
              <span>
                {discount?.toFixed(decimal_preference)} {arCurrency}
              </span>
            </li>
          </>
        )}
        <hr />
        <li className={classes.ChargesInfoListItemLarge}>
          <span>Total Payable</span>
          <span>
            {currency} {totalPayable?.toFixed(decimal_preference)}
          </span>
        </li>
        <li className={classes.ChargesInfoListItemLarge}>
          <span>إجمالي المدفوعات</span>
          <span>
            {totalPayable?.toFixed(decimal_preference)} {arCurrency}
          </span>
        </li>
      </ul>

      <IconButton
        icon="arrow-right"
        iconPosition="right"
        text="Proceed to Pay   المضي قدما في الدفع"
        customClasses={classes.ProceddBtn}
        onClicked={onProceed}
      />
    </Modal>
  );
};

export default CommissionModal;
