import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { useTranslation } from "react-i18next";
// import { ResturantService } from "services/ResturantService";
import classes from "./DuplicateReceipt.module.scss";
import { changeActiveCart } from "redux/cart";
import moment from "../../../../../node_modules/moment/moment";
import Payment from "components/Cart/Payment/Payment";
import IconButton from "components/IconButton/IconButton";
import { useGetActiveLocation } from "hooks/useGetActiveLocation";
import { numberFormat } from "utillity/utils";
import SingleOrderType from "components/Cart/SingleOrderType";
import { OrderServices } from "services/OrderServices";
import { useCallback } from "react";
import { AuthContext } from "context/authContext";
import Loader from "components/Loader/Loader";
import PrimaryButton from "components/PrimaryButton/PrimaryButton";
import MainReceipt from "components/receipt/MainReceipt";
import { useGetTenderTaxHook } from "hooks/useGetTenderTaxHook";
import SingleListItem from "components/PrimaryDrawerCart/SingleListItem";
import { useGetActiveLocationWithOrderTab } from "hooks/useTaxHooks";
import { useCartHooks } from "hooks/useCartHooks";
import SplitReceipt from "components/receipt/SplitReceipt";
import { fetchOrderSplits } from "redux/split";

const DuplicateReciept = () => {
  const { token, manualLogout } = useContext(AuthContext);

  const { selectedOrders } = useSelector((state) => state.editOrder);
  // const [smallRestaurantLogo, setSmallRestaurantLogo] = useState(null);
  console.log(selectedOrders, "selectedOxrders");
  const dispatch = useDispatch();
  const cartState = useSelector((state) => state.cart);
  // const [restaurant, setRestaurant] = useState({});
  const { t } = useTranslation();
  const receiptRef = useRef();
  const { vatReceipt } = useCartHooks();
  const { currency, decimal_preference, currentLocation } = useGetActiveLocation();
  const { order } = useSelector((state) => state.editOrder);

  const { translations: servicesTranslations } = useSelector(
    (state) => state.service
  );
  const { checkoutChannel } = useSelector((state) => state.channel);

  const [activeTab, setActiveTab] = useState({});
  const { checkoutTender } = useSelector((state) => state.tender);

  const { tax } = useGetActiveLocationWithOrderTab({
    activeTab,
    checkoutChannel,
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [mainOrder, setMainOrder] = useState(null);
  const [qrCode, setQrCode] = useState("");
  const [orderSplits, setOrderSplits] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  console.log(totalAmount, "totalAmount");
  const activeOrder =
    selectedOrders &&
    selectedOrders.filter((item) => item?.type === cartState.activeCart);

  // Getting the order details
  const getOrder = useCallback(() => {
    setLoading(true);
    setError("");
    OrderServices.getOrder(
      { orderNumber: activeOrder[0]?.order_no },
      (res) => {
        setLoading(false);
        // console.log(res, "Res");
        setMainOrder(res?.data?.data?.data);
        setQrCode(res?.data?.data?.data?.qr_code);
      },
      (err) => {
        setLoading(false);
        if (err && err?.response?.status === 401) {
          manualLogout();
        } else {
          setError(
            err?.response?.data?.message || "Error occurred while getting order"
          );
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedOrders) {
      // setOrderTypes(selectedOrders);
      setActiveTab(selectedOrders[0]);
    }
  }, [selectedOrders]);
  const gethOrderSplits = async () => {
    try {
      if (!order?.id) return;
      
      const result = await dispatch(fetchOrderSplits({
        orderId: order.id,
        token,
        locationId: order.location_id
      })).unwrap();

      setOrderSplits(result.splits);
      setTotalAmount(result.splits.reduce((acc, curr) => acc + Number(curr.final_amount), 0));

    } catch (error) {
      console.error('Error fetching order splits:', error);
    }
  };
  useEffect(() => {
    if (order?.id) {
      gethOrderSplits();
    }
  }, [order?.id]);
  /**
   * @function To get the active tab total price
   * @param It takes the active order tab
   */
  const getActiveTabTotalPrice = (val) => {
    let price = 0;
    const currentTab = Object.keys(cartState?.cart);
    currentTab.forEach((el) => {
      const currentOrderArray = cartState?.cart[val];
      // If the length of currentOrderArray is greater than 1
      if (currentOrderArray?.length >= 1) {
        if (val === el) {
          for (const cartItem of cartState?.cart[val] || []) {
            const item_price =
              +(cartItem?.amount) * cartItem?.quantity;

            // Calculate the discounted value for the current item
            const discountedValue =
              cartItem.discount_type === "percentage"
                ? item_price -
                  (item_price * Number(cartItem.discount_value ?? 0)) / 100
                : item_price - Number(cartItem.discount_value ?? 0);
            // Add the discounted value to the total price
            price += discountedValue;
          }
        }
      } else {
        price = 0;
      }
    });

    const activeOrderDiscount = selectedOrders.find(
      (orderItem) => orderItem.type === val
    );
    const priceAfterDiscount =
      Number(activeOrderDiscount?.discount_value) > 0
        ? activeOrderDiscount?.discount_type === "percentage"
          ? price - (price * Number(activeOrderDiscount?.discount_value)) / 100
          : price - Number(activeOrderDiscount?.discount_value)
        : price;

    return priceAfterDiscount.toFixed(3);
  };

  const getSubTotalPrice = () => {
    let subTotalPrice = 0;
    if (cartState?.cart && cartState?.cart[cartState?.activeCart]) {
      for (const item of cartState?.cart[cartState?.activeCart]) {
        subTotalPrice += Number(item?.price) * Number(item?.quantity);
      }
    }
    return Number(subTotalPrice.toFixed(3));
  };

  const getTotalTaxAmount = () => {
    if (!currentLocation?.taxes) return 0;
    
    const baseAmount = getSubTotalPrice();
    return currentLocation.taxes.reduce((total, tax) => {
      const taxAmount = (baseAmount * tax.tax_value) / 100;
      return total + Number(taxAmount.toFixed(decimal_preference));
    }, 0);
  };

  const getTotalTenderTax = () => {
    let tenderTaxTotal = 0;
    
    // Get tender tax from splits
    if (orderSplits && orderSplits.length > 0) {
      tenderTaxTotal = orderSplits
        .map((split) => Number(split.tender_tax_applied) || 0)
        .reduce((a, b) => a + b, 0);
    }

    // Add additional tender fee if showTenderTax is true
    if (showTenderTax && tenderFee) {
      tenderTaxTotal += Number(tenderFee);
    }

    console.log('Tender Tax Calculation:', {
      fromSplits: orderSplits?.reduce((a, b) => a + Number(b.tender_tax_applied || 0), 0),
      fromTenderFee: showTenderTax ? tenderFee : 0,
      total: tenderTaxTotal
    });

    return tenderTaxTotal;
  };

  const getPriceWithTax = () => {
    const baseAmount = getSubTotalPrice();
    const regularTax = getTotalTaxAmount();
    const tenderTax = getTotalTenderTax();
    
    // Include all taxes in the total
    const total = baseAmount + regularTax + tenderTax;
    
    console.log('Price With Tax Calculation:', {
      baseAmount,
      regularTax,
      tenderTax,
      showTenderTax,
      tenderFee,
      total
    });
    
    return Number(total.toFixed(2));
  };

  const getPayableAmount = () => {
    const total = getPriceWithTax();
    return Math.round(total); // Round to nearest whole number
  };

  const { showTenderTax, tenderFee } = useGetTenderTaxHook({
    tax: mainOrder?.tender?.tax,
    tax_type: mainOrder?.tender?.tax_type,
    order_total: getSubTotalPrice(),
  });

  const getTotalTax = () => {
    const orderTax = mainOrder && mainOrder?.tax ? Number(mainOrder?.tax) : 0;

    return numberFormat(orderTax, 3);
  };

  // const getTotalPrice = () => {
  //   let totalPrice = 0;
  //   if (checkoutChannel && checkoutChannel?.commission) {
  //     totalPrice += getSubTotalPrice();
  //     totalPrice += getTotalTax();
  //     totalPrice += getChannelPrice();
  //   } else {
  //     totalPrice += getSubTotalPrice();
  //     totalPrice += getTotalTax();
  //   }

  //   return numberFormat(totalPrice, 3);
  // };

  const radioChangeHandler = (newValue) => {
    dispatch(changeActiveCart(newValue));
  };

  const handleOrderPrintingRecord = () => {
    OrderServices.printOrderReceipt(
      { order_id: mainOrder?.id },
      (res) => {
        console.log("Res", res);
      },
      (err) => {
        console.log("Error", err);
      }
    );
  };

  const handlePrient = useReactToPrint({
    content: () => receiptRef.current,
    onAfterPrint: handleOrderPrintingRecord,
  });

  const showChannel =
    mainOrder && mainOrder?.commission && Number(mainOrder?.commission) > 0
      ? true
      : false;
  // const deliveryFee =
  //   mainOrder && mainOrder?.commission ? Number(mainOrder?.commission) : 0;

  const showDiscount =
    mainOrder &&
    mainOrder?.total_discount &&
    Number(mainOrder?.total_discount) > 0
      ? true
      : false;
  const discountValue =
    mainOrder && mainOrder?.total_discount
      ? Number(mainOrder?.total_discount)
      : 0;
  const discountType =
    mainOrder && mainOrder?.discount_type
      ? mainOrder?.discount_type
      : "percentage";

  // Get channel commission if available
  const getChannelPrice = () => {
    let channelFee = 0;
    if (showChannel) {
      // const fee = (deliveryFee * (getSubTotalPrice())) / 100;
      channelFee =
        mainOrder &&
        mainOrder?.delivery_channel?.commission_type === "percentage"
          ? Number(mainOrder?.commission_amount)
          : Number(mainOrder?.commission);
    }
    return numberFormat(channelFee, 3);
  };

  // Getting total discount from API result
  const discountPrice = () => {
    let discount = 0.0;
    if (showDiscount && discountType === "percentage") {
      const mainDiscount = discountValue;
      discount = mainDiscount;
      return discount;
    } else if (
      showDiscount &&
      (discountType === "fixed" || discountType === "amount")
    ) {
      return discountValue;
    } else {
      return discount;
    }
  };

  // Calculating the total price
  const getOrderTotalPrice = () => {
    let totalPrice = 0;
    
    // Add base price and regular tax
    totalPrice += getSubTotalPrice();
    totalPrice += getTotalTax();
    
    // Add channel fee if applicable
    if (showChannel) {
      totalPrice += Number(getChannelPrice());
    }
    
    // Add all tender tax amounts from splits
    if (orderSplits && orderSplits.length > 0) {
      orderSplits.forEach(split => {
        totalPrice += Number(split.tender_tax_applied || 0);
      });
    }
    
    // Add tender fee from hook
    totalPrice += Number(tenderFee || 0);
    
    // Subtract discount if applicable
    if (showDiscount) {
      totalPrice -= Number(discountPrice() || 0);
    }

    return numberFormat(totalPrice, 3);
  };



  if (loading) {
    return (
      <div className="h-screen">
        <Loader />
      </div>
    );
  } else if (error) {
    return (
      <div className="w-full h-[80vh]  flex flex-col items-center justify-center">
        <p className="text-3xl lg:text-4xl font-Inter font-medium text-red-300">
          Error occurred while fetching order details
        </p>
        <div className="mt-16 w-full flex flex-row items-center justify-center">
          <PrimaryButton
            text="Try Again"
            onClicked={() => {
              getOrder();
            }}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={classes.ReceiptPage}>
      <div className={classes.CartBox}>
        <div className={classes.Cart}>
          <div className={classes.CartHeader}>
            {mainOrder && mainOrder?.order_no && (
              <p>
                Order ID <span className="">#{mainOrder?.order_no}</span>
              </p>
            )}
            <div>
              {showChannel && (
                <img
                  src={mainOrder?.delivery_channel?.logo}
                  alt={`${mainOrder?.delivery_channel?.name} logo`}
                  className="block w-[50px] h-[50px]"
                />
              )}
            </div>
          </div>

          <p className="text-right">
            {moment().format("hh:mm:ss a DD/MM/yyyy")}
          </p>
          {mainOrder && mainOrder?.order_type === "dine in" && (
            <p className={classes.OrderTableText}>
              Table - {mainOrder?.outletTable?.table_number}
            </p>
          )}
          <div className={classes.OrderTypesAndDate}>
            {Object.keys(cartState.cart).length > 0 && (
              <div>
                <div className="w-full grid grid-cols-3 gap-5 pt-4 ">
                  {[cartState.cart && cartState?.activeCart]?.map(
                    (type, index) => {
                      return (
                        <SingleOrderType
                          key={type}
                          textLg1={type}
                          value={type}
                          textLg2={servicesTranslations?.ar[type] || ""}
                          isChecked={cartState?.activeCart === type}
                          onChanged={radioChangeHandler}
                          totalCartPrice={getActiveTabTotalPrice(type)}
                          active={cartState?.activeCart}
                        />
                      );
                    }
                  )}
                </div>
              </div>
            )}
          </div>

          {/* Content */}
          <ul className={classes.ItemList}>
            {Object.keys(cartState?.cart).length > 0 &&
              cartState?.cart[cartState.activeCart]?.map((item, index) => {
                return (
                  <SingleListItem
                      item={item}
                      key={Math.random(100)}
                      id={item.item_id}
                      index={index + 1}
                      quantity={item.quantity}
                      price={item.amount}
                      sizes={item?.sizes}
                      translations={item?.translations}
                      name={item?.item_name}
                    />
                );
              })}
          </ul>
          <br />
          <div className={classes.PaymentTab}>
            <Payment text={t("Price without tax")} price={getSubTotalPrice()} />
            
            {currentLocation?.taxes && currentLocation.taxes.length > 0 && (
              currentLocation.taxes.map((tax, index) => (
                <Payment 
                  key={index}
                  text={t(`Tax (${tax?.translations?.en?.name || 'Tax'} ${tax?.tax_value || 0}%)`)} 
                  price={Number((getSubTotalPrice() * tax.tax_value / 100).toFixed(decimal_preference))} 
                />
              ))
            )}
            {
              orderSplits && orderSplits.length > 0 && (
              orderSplits.map((split, index) => {
  // Calculate tender tax based on tax type
              let tenderTaxAmount = 0;
              if (split.tender_tax_type?.toLowerCase() === 'percentage') {
                tenderTaxAmount = (Number(split.final_amount) * Number(split.tender_tax_value)) / 100;
              } else if (split.tender_tax_type?.toLowerCase() === 'fixed') {
                tenderTaxAmount = Number(split.tender_tax_applied);
              }
              return (
                <Payment 
                  key={index} 
                  text={orderSplits?.length > 0 ? t(`Tender tax (${split.tender_name})`) : ""} 
                  price={Number(split.tender_tax_applied)} 
                />
              );
            })
            )}
           
            {showDiscount && (
              <>
                {discountType === "percentage" ? (
                  <Payment text={t(`Discount`)} price={discountPrice()} />
                ) : (
                  <Payment text={t(`Discount`)} price={discountPrice()} />
                )}
              </>
            )}
            
            {showTenderTax && (
              <Payment
                text={t(`Tax (${tax}٪)`)} 
                price={tenderFee}
              />
            )}
            
            <Payment 
              text={t("Price With Tax")} 
              price={getPriceWithTax()} 
            />
            
            <Payment
              text={t("PAYABLE_AMOUNT")}
              price={getPayableAmount()}
              size="large"
            />
          </div>
        </div>
        {
            orderSplits.length > 0 && (
            <div className={classes.SplitReceiptsContainer}>
              {orderSplits.map((split) => (
                <div key={split.id}>
                  <SplitReceipt 
                  split={split} 
                  totalAmount={totalAmount}
                  activeTab={activeTab}
                  getSubTotalPrice={getSubTotalPrice}
                  getChannelPrice={getChannelPrice}
                  // getTotalPrice={getTotalPrice}
                  // isPaid={isPaid}
                  showChannel={showChannel}
                  // showTax={taxEnabled}
                  // showDiscount={showDiscountReceipt}
                  // getDiscount={discountPriceReceipt}
                  qrCode={qrCode}
                  isDuplicate={false}
                  showTenderTax={showTenderTax}
                  tenderFee={tenderFee}
                  />
                </div>
              ))}
            </div>
            )
          }
      </div>
      <div className={classes.ReceiptBox}>
        <div className={classes.ReceiptLanguageSwitcher}>
          <span>Preview</span>
        </div>
        <div ref={receiptRef}>
          <MainReceipt
            getSubTotalPrice={getSubTotalPrice}
            getChannelPrice={getChannelPrice}
            getTotalPrice={getOrderTotalPrice}
            mainOrder={mainOrder}
            duplicateSplits={orderSplits}
            isDuplicate={true}
            showChannel={showChannel}
            showTax={true}
            showDiscount={showDiscount}
            getDiscount={discountPrice}
            qrCode={qrCode}
            showTenderTax={showTenderTax}
            tenderFee={tenderFee}
            orderCreatedAt={mainOrder?.created_at}
          />
        </div>
        <IconButton
          icon="print"
          iconPosition="left"
          text="Print Receipt Only"
          customClasses={classes.PrintBtn}
          onClicked={() => handlePrient()}
        />
      </div>
    </div>
  );
};

export default DuplicateReciept;
