import React, { useLayoutEffect, useState } from "react";
import classes from "./MainReceipt.module.scss";
import { useSelector } from "react-redux";
import { ResturantService } from "services/ResturantService";
import logo from "assets/svg/defaultJalebi.svg";
import { useGetActiveLocation } from "hooks/useGetActiveLocation";
import ReceiptBuilder from "./ReceiptBuilder";

const MainReceipt = ({
  isDuplicate,
  orderSplits,
  duplicateSplits,
  getChannelPrice,
  getSubTotalPrice,
  getTotalPrice,
  isPaid = false,
  // mainOrder,
  showChannel,
  showTax,
  showDiscount,
  getDiscount,
  qrCode,
  showTenderTax,
  tenderFee,
  splitBillFinalAmount,
  type = "",
  taxes
}) => {
  const { duplicateOrder } = useSelector((state) => state.duplicateOrder);
  const { order: editOrder } = useSelector((state) => state.editOrder);
  const { currentLocation } = useGetActiveLocation();
  const [smallRestaurantLogo, setSmallRestaurantLogo] = useState(null);
  const [restaurant, setRestaurant] = useState({});
  // const { discountPriceReceipt, showDiscountReceipt } = useCartHooks();

  useLayoutEffect(() => {
    ResturantService.getResturantInfo(
      (res) => {
        setRestaurant(res?.data?.data?.data || {});
        setSmallRestaurantLogo(res?.data?.data?.data?.smallimage);
      },
      (err) => {
        console.log({ err });
      }
    );
  }, []);

  const order = isDuplicate ? duplicateOrder : editOrder;

  const receiptOrder = JSON.parse(localStorage.getItem(type === "staffReceipt" ? "staffReceipt" : "receiptOrder"));

  // Group similar item based on their order_index
  const groupByKey = (list, key) =>
    list?.reduce(
      (hash, obj) => ({
        ...hash,
        [obj[key]]: (hash[obj[key]] || [])?.concat(obj),
      }),
      []
    );

  // Get the values of each keys which are arrays
  const reformedReceipt = Object.values(
    groupByKey(receiptOrder, "order_index")
  );

  return (
    <div className={classes.Receipt}>
      {reformedReceipt?.reverse().map((item, index) => (
        <div className="w-full" key={index}>
          {item?.length === 1 && (
            <div className="w-full">
              <ReceiptBuilder
                item={item[0]}
                orderSplits={orderSplits}
                duplicateSplits={duplicateSplits}
                isDuplicate={isDuplicate}
                splitBillFinalAmount={splitBillFinalAmount}
                logo={smallRestaurantLogo || logo}
                restaurant={restaurant}
                currentLocation={currentLocation}
                order={order}
                showTax={showTax}
                getSubTotalPrice={getSubTotalPrice}
                showDiscount={showDiscount}
                getDiscount={getDiscount}
                showChannel={showChannel}
                getChannelPrice={getChannelPrice}
                getTotalPrice={getTotalPrice}
                isPaid={isPaid}
                qrCode={qrCode}
                showTenderTax={showTenderTax}
                tenderFee={tenderFee}
                receiptType={type}
                taxes={taxes}
              />
            </div>
          )}
          {item?.length === 2 && (
            <div className="w-full flex flex-row items-center justify-between  pb-2">
              <ReceiptBuilder
                item={item[0]}
                isDuplicate={isDuplicate}
                duplicateSplits={duplicateSplits}
                logo={smallRestaurantLogo || logo}
                restaurant={restaurant}
                currentLocation={currentLocation}
                order={order}
                showTax={showTax}
                getSubTotalPrice={getSubTotalPrice}
                showDiscount={showDiscount}
                getDiscount={getDiscount}
                showChannel={showChannel}
                getChannelPrice={getChannelPrice}
                getTotalPrice={getTotalPrice}
                isPaid={isPaid}
                qrCode={qrCode}
                showTenderTax={showTenderTax}
                tenderFee={tenderFee}
                receiptType={type}
                taxes={taxes}
              />
              <ReceiptBuilder
                item={item[1]}
                isDuplicate={isDuplicate}
                duplicateSplits={duplicateSplits}
                logo={smallRestaurantLogo || logo}
                restaurant={restaurant}
                currentLocation={currentLocation}
                order={order}
                showTax={showTax}
                getSubTotalPrice={getSubTotalPrice}
                showDiscount={showDiscount}
                getDiscount={getDiscount}
                showChannel={showChannel}
                getChannelPrice={getChannelPrice}
                getTotalPrice={getTotalPrice}
                isPaid={isPaid}
                qrCode={qrCode}
                showTenderTax={showTenderTax}
                tenderFee={tenderFee}
                receiptType={type}
                taxes={taxes}
              />
            </div>
          )}
          {item?.length === 3 && (
            <div className="w-full flex flex-row items-center justify-between">
              <ReceiptBuilder
                item={item[0]}
                isDuplicate={isDuplicate}
                logo={smallRestaurantLogo || logo}
                restaurant={restaurant}
                currentLocation={currentLocation}
                order={order}
                showTax={showTax}
                getSubTotalPrice={getSubTotalPrice}
                showDiscount={showDiscount}
                getDiscount={getDiscount}
                showChannel={showChannel}
                getChannelPrice={getChannelPrice}
                getTotalPrice={getTotalPrice}
                isPaid={isPaid}
                qrCode={qrCode}
                showTenderTax={showTenderTax}
                tenderFee={tenderFee}
                receiptType={type}
                taxes={taxes}
              />
              <ReceiptBuilder
                item={item[1]}
                isDuplicate={isDuplicate}
                logo={smallRestaurantLogo || logo}
                restaurant={restaurant}
                currentLocation={currentLocation}
                order={order}
                showTax={showTax}
                getSubTotalPrice={getSubTotalPrice}
                showDiscount={showDiscount}
                getDiscount={getDiscount}
                showChannel={showChannel}
                getChannelPrice={getChannelPrice}
                getTotalPrice={getTotalPrice}
                isPaid={isPaid}
                qrCode={qrCode}
                showTenderTax={showTenderTax}
                tenderFee={tenderFee}
                receiptType={type}
                taxes={taxes}
              />
              <ReceiptBuilder
                item={item[2]}
                isDuplicate={isDuplicate}
                logo={smallRestaurantLogo || logo}
                restaurant={restaurant}
                currentLocation={currentLocation}
                order={order}
                showTax={showTax}
                getSubTotalPrice={getSubTotalPrice}
                showDiscount={showDiscount}
                getDiscount={getDiscount}
                showChannel={showChannel}
                getChannelPrice={getChannelPrice}
                getTotalPrice={getTotalPrice}
                isPaid={isPaid}
                qrCode={qrCode}
                showTenderTax={showTenderTax}
                tenderFee={tenderFee}
                receiptType={type}
                taxes={taxes}
              />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default MainReceipt;
