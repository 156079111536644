import Modal from "components/Modal/Modal";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import classes from "./OrderDiscountNew.module.scss";
// import { useCartHooks } from "hooks/useCartHooks";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import SingleDesktopFilterSelect from "pages/OrdersPage/AllOrdersPage/components/SingleDesktopFilterSelect";
import { ORDER_DISCOUNT_TYPE } from "utillity/productionData";
import DiscountByOrder from "./DiscountByOrder";
import DiscountByItem from "./DiscountByItem";
const OrderDiscountModalNew = ({
  showModal,
  toggleModal,
  onProceed = () => {},
  onFocus = () => {},
  label = "",
  disabled = true,
  activeItem,
}) => {
  const cartState = useSelector((state) => state.cart);
  const { selectedOrders } = useSelector((state) => state.editOrder);

  const [shallowCart, setShallowCart] = useState(cartState);
  const [selectedDiscountType, setSelectedDiscountType] = useState(
    ORDER_DISCOUNT_TYPE[0]
  );

  const activeOrderDiscounts = selectedOrders.find(
    (item) => item.type === shallowCart.activeCart
  );

  const [discountInfo, setDiscountInfo] = useState({
    discount_type: "percentage",
    discount_value: 0,
  });

  const onChangeFilter = useCallback((newValue) => {
    setSelectedDiscountType(newValue);
  }, []);

  useMemo(() => {
    setShallowCart(cartState);
  }, [cartState]);
  useEffect(() => {
    if (
      activeOrderDiscounts &&
      activeOrderDiscounts?.discount_value > 0 &&
      activeOrderDiscounts?.discount_type
    ) {
      setDiscountInfo({
        discount_type: activeOrderDiscounts?.discount_type || "percentage",
        discount_value: Number(activeOrderDiscounts.discount_value) ?? 0,
      });
      setSelectedDiscountType({
        textLg1: "By Order",
        value: "order",
      });
    } else {
      setDiscountInfo({
        discount_type: "percentage",
        discount_value: 0,
      });
      setSelectedDiscountType({
        textLg1: "By Item",
        value: "item",
      });
    }
  }, [activeOrderDiscounts]);

  const orderTotalPriceByItem = () => {
    const cartTotal = shallowCart?.cart[shallowCart?.activeCart]?.reduce(
      (total, item) => {
        return total + item.amount;
      },
      0
    );
    return cartTotal;
  };

  const cartTotalAmountByItem = orderTotalPriceByItem();

  const calculateCartTotalTax = () => {
    const cartTotal = shallowCart?.cart[shallowCart?.activeCart]?.reduce(
      (total, item) => {
        return total + item.vat * item.quantity;
      },
      0
    );
    return cartTotal;
  };

  const cartTotalTax = calculateCartTotalTax();

  function calculateDiscountByItem(item) {
    if (item.discount_type === "percentage") {
      return (
        (Number(item.discount_value ?? 0) / 100) *
        (Number(item?.amount) * item?.quantity)
      );
    } else if (item.discount_type === "fixed") {
      return Number(item.discount_value ?? 0);
    } else {
      return 0;
    }
  }

  function calculateTotalDiscountByItem(items) {
    let totalDiscount = 0;
    for (let item of items) {
      totalDiscount += parseFloat(calculateDiscountByItem(item));
    }
    return totalDiscount;
  }

  const calculatePayableAmountByItem = () => {
    const totalDiscount = calculateTotalDiscountByItem(
      shallowCart.cart[shallowCart.activeCart]
    );

    return cartTotalAmountByItem  - totalDiscount;
  };

  const payableAmountByItem = shallowCart.cart[shallowCart.activeCart]?.length
    ? calculatePayableAmountByItem()
    : 0;

  const calculteOrderTotalPrice = () => {
    const cartTotal = shallowCart?.cart[shallowCart?.activeCart]?.reduce(
      (total, item) => {
        return total + item.amount * item.quantity;
      },
      0
    );
    return cartTotal;
  };
  const cartTotalAmountByOrder = calculteOrderTotalPrice();

  const proceed = () => {
    if (selectedDiscountType.value === "order") {
      const discountType = discountInfo.discount_type;
      const discountValue = Number(discountInfo.discount_value);
      if (discountType === "fixed") {
        if (
          Number(discountValue) >= 0 &&
          Number(discountValue) <= cartTotalAmountByOrder + cartTotalTax
        ) {
          const payload = {
            discount_by: "by_order",
            items: shallowCart?.cart[shallowCart?.activeCart].map((item) => ({
              id: item.item_id,
              qty: item.quantity,
            })),
            discount_value: discountValue,
            discount_type: discountType,
          };

          toast.success("Discount applied successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
            toastId: Math.floor(Math.random() * 990) + 10,
          });
          onProceed(
            { payload, type: shallowCart.activeCart },
            {
              type: selectedDiscountType.value,
              discountInfo,
              cartType: shallowCart.activeCart,
            }
          );
          // DiscountServices.getAppliedDiscount(payload, (res) => {
          //   if (res.status === 200) {
          //     toast.success("Discount applied successfully", {
          //       position: toast.POSITION.BOTTOM_RIGHT,
          //       toastId: Math.floor(Math.random() * 990) + 10,
          //     });
          //     onProceed(
          //       { payload, type: shallowCart.activeCart },
          //       {
          //         type: selectedDiscountType.value,
          //         discountInfo,
          //         cartType: shallowCart.activeCart,
          //       }
          //     );
          //   } else {
          //     toast.error("Failed to apply Discount", {
          //       position: toast.POSITION.BOTTOM_RIGHT,
          //       toastId: Math.floor(Math.random() * 990) + 10,
          //     });
          //   }
          // });
        } else {
          toast.warning(
            `Discount value should be between 0 and ${
              cartTotalAmountByOrder + cartTotalTax
            } for fixed discount`,
            {
              position: toast.POSITION.BOTTOM_RIGHT,
              toastId: Math.floor(Math.random() * 990) + 10,
            }
          );
        }
      } else if (discountType === "percentage") {
        if (Number(discountValue) >= 0 && Number(discountValue) <= 100) {
          const payload = {
            discount_by: "by_order",
            items: shallowCart?.cart[shallowCart?.activeCart].map((item) => ({
              id: item.item_id,
              qty: item.quantity,
            })),
            discount_value: discountValue,
            discount_type: discountType,
          };

          toast.success("Discount applied successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
            toastId: Math.floor(Math.random() * 990) + 10,
          });
          onProceed(
            { payload, type: shallowCart.activeCart },
            {
              type: selectedDiscountType.value,
              discountInfo,
              cartType: shallowCart.activeCart,
            }
          );

          // DiscountServices.getAppliedDiscount(payload, (res) => {
          //   if (res.status === 200) {
          //     toast.success("Discount applied successfully", {
          //       position: toast.POSITION.BOTTOM_RIGHT,
          //       toastId: Math.floor(Math.random() * 990) + 10,
          //     });
          //     onProceed(
          //       { payload, type: shallowCart.activeCart },
          //       {
          //         type: selectedDiscountType.value,
          //         discountInfo,
          //         cartType: shallowCart.activeCart,
          //       }
          //     );
          //   } else {
          //     toast.error("Failed to apply Discount", {
          //       position: toast.POSITION.BOTTOM_RIGHT,
          //       toastId: Math.floor(Math.random() * 990) + 10,
          //     });
          //   }
          // });
        } else {
          toast.warning(
            `Discount value should be between 0 and 100 for percentage discount`,
            {
              position: toast.POSITION.BOTTOM_RIGHT,
              toastId: Math.floor(Math.random() * 990) + 10,
            }
          );
        }
      }
    } else if (selectedDiscountType.value === "item") {
      if (
        calculateTotalDiscountByItem(
          shallowCart.cart[shallowCart.activeCart]
        ) < 0
      ) {
        toast.warning(
          `Discount value should be between 1 and ${
            cartTotalAmountByItem + cartTotalTax
          } for discount`,
          {
            position: toast.POSITION.BOTTOM_RIGHT,
            toastId: Math.floor(Math.random() * 990) + 10,
          }
        );
      } else if (
        calculateTotalDiscountByItem(shallowCart.cart[shallowCart.activeCart]) >=
          0 &&
        payableAmountByItem >= 0 &&
        payableAmountByItem <= cartTotalAmountByItem + cartTotalTax
      ) {

        const discountedItems = shallowCart.cart[
          `${shallowCart.activeCart}`
        ].filter((item) => Number(item.discount_value) >= 0);

        const payload = {
          discount_by: "by_item",
          items: discountedItems.map((item) => ({
            id: item.item_id,
            qty: item.quantity,
            discount_value: Number(item.discount_value) ?? 0,
            discount_type: item.discount_type,
          })),
        };
        toast.success("Discount applied successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
          toastId: Math.floor(Math.random() * 990) + 10,
        });
        onProceed(
          { payload, type: shallowCart.activeCart },
          {
            type: selectedDiscountType.value,
            discountInfo,
            cartType: shallowCart.activeCart,
          }
        );
        // DiscountServices.getAppliedDiscount(payload, (res) => {
        //   if (res.status === 200) {
        //     toast.success("Discount applied successfully", {
        //       position: toast.POSITION.BOTTOM_RIGHT,
        //       toastId: Math.floor(Math.random() * 990) + 10,
        //     });
        //     const updatedItems = payload.items.map((payloadItem) => {
        //       // Find corresponding item in res.data.data
        //       const resItem = res.data.data.find(
        //         (resItem) => resItem.id === payloadItem.id
        //       );

        //       // If resItem exists, copy the saved property to payloadItem
        //       if (resItem) {
        //         return {
        //           ...payloadItem,
        //           saved: resItem.saved,
        //         };
        //       } else {
        //         return payloadItem;
        //       }
        //     });

        //     // Update items in payload with saved property copied from resItem
        //     payload.items = updatedItems;
        //     onProceed(
        //       { payload, type: shallowCart.activeCart },
        //       {
        //         type: selectedDiscountType.value,
        //         discountInfo,
        //         cartType: shallowCart.activeCart,
        //       }
        //     );
        //   } else {
        //     toast.error("Please enter valid discount values.", {
        //       position: toast.POSITION.BOTTOM_RIGHT,
        //       toastId: Math.floor(Math.random() * 990) + 10,
        //     });
        //   }
        // });
      } else {
        toast.warning(`Please Enter valid discount value`, {
          position: toast.POSITION.BOTTOM_RIGHT,
          toastId: Math.floor(Math.random() * 990) + 10,
        });
      }
    }
  };

  return (
    <Modal
      show={showModal}
      onClickOutside={toggleModal}
      customClasses={classes.CustomModal}
    >
      <div className={classes.ModalHeader}>
        <p>Add Discount</p>
      </div>
      {/* Inputs */}
      <div className={classes.content}>
        <div className="filters-btn flex pb-7">
          {ORDER_DISCOUNT_TYPE.map((item, index) => (
            <SingleDesktopFilterSelect
              item={item}
              key={index}
              active={selectedDiscountType}
              onChangeFilter={onChangeFilter}
              className="my-5"
            />
          ))}
        </div>

        <div className="">
          {selectedDiscountType.value === "order" && (
            <DiscountByOrder
              label={label}
              discountInfo={discountInfo}
              setDiscountInfo={setDiscountInfo}
              cartTotalAmount={cartTotalAmountByOrder}
              onFocus={onFocus}
              cartTotalTax={cartTotalTax}
            />
          )}
          {selectedDiscountType.value === "item" && (
            <DiscountByItem
              shallowCart={shallowCart}
              setShallowCart={setShallowCart}
              payableAmount={payableAmountByItem}
              cartTotalAmount={cartTotalAmountByItem}
              cartTotalTax={cartTotalTax}
            />
          )}
        </div>

        {/* Buttons */}
        <div className="w-full mt-[15rem] flex flex-row justify-end">
          <div className="w-full flex flex-row items-center justify-between space-x-3">
            {/* Cancel btn */}
            <button
              tabIndex="0"
              onClick={toggleModal}
              className="py-4 w-1/2 bg-gray-100 flex-row items-center justify-center cursor-pointer"
            >
              <p className="text-[#1F2937] lg:text-xl text-base text-center">
                Cancel <span>يلغي</span>
              </p>
            </button>
            {/* Cancel Order btn */}
            <button
              tabIndex="0"
              onClick={proceed}
              className={
                !disabled
                  ? "flex-row w-1/2 items-center justify-center py-4 bg-[#9BA3AF] rounded "
                  : "flex-row w-1/2 items-center justify-center py-4 bg-[#F3781F] rounded cursor-pointer"
              }
            >
              <p className="text-white lg:text-xl text-base text-center">
                Save <span>يحفظ</span>
              </p>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default OrderDiscountModalNew;
