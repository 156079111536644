import axiosWithLocation from "config/axiosWithLocationV2";

export const TableServices = {
  createTable: function (table, success, fail) {
    axiosWithLocation.post("/outlet/tables", table).then(success).catch(fail);
  },
  updateTable: function (table, success, fail) {
    axiosWithLocation
      .put("/outlet/tables/" + table.id, table)
      .then(success)
      .catch(fail);
  },
  deleteTable: function (tableId, success, fail) {
    axiosWithLocation
      .delete("/outlet/tables/" + tableId)
      .then(success)
      .catch(fail);
  },
  mergeTable: function (table_ids, success, fail) {
    axiosWithLocation.post("/outlet/tables/merge", table_ids).then(success).catch(fail);
  },
  unmergeTable: function (merged_table_id, success, fail) {
    axiosWithLocation.post("/outlet/tables/unmerge", merged_table_id).then(success).catch(fail);
  },
  transferTable: function (tableData, success, fail) {
    axiosWithLocation.post("/outlet/tables/transfer", tableData).then(success).catch(fail);
  },
};
