import { useGetActiveLocation } from "hooks/useGetActiveLocation";
import classes from "./Payment.module.scss";

const Payment = ({ text, price, size }) => {
  const { decimal_preference } = useGetActiveLocation();
  let styleClasses = classes.Payment;

  switch (size) {
    case "large":
      styleClasses = [styleClasses, classes.Large].join(" ");
      break;

    default:
      break;
  }
  return (
    <div className={styleClasses}>
      <span className={classes.PaymentText}>{text}</span>
      <span className={classes.PaymentPrice}>
        {price.toFixed(decimal_preference)}
      </span>
    </div>
  );
};

export default Payment;
