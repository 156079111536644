import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import classes from "./LiveOrderReceipt.module.scss";
import logo from "assets/svg/defaultJalebi.svg";
import { useTranslation } from "react-i18next";
import { ResturantService } from "services/ResturantService";
import DelevirectDrawerHeader from "components/deliverectDrawerCart/component/DelevirectDrawerHeader";
import DeliverectDrawerTabHeader from "components/deliverectDrawerCart/component/DeliverectDrawerTabHeader";
import SingleListItem from "components/PrimaryDrawerCart/SingleListItem";
import Payment from "components/Cart/Payment/Payment";
import { numberFormat } from "utillity/utils";
import moment from "../../../../../node_modules/moment/moment";
import { useGetActiveLocation } from "hooks/useGetActiveLocation";
import IconButton from "components/IconButton/IconButton";
import { useReactToPrint } from "react-to-print";

const LiveOrdersReceipt = () => {
  const { deliverectOrder } = useSelector((state) => state.editOrder);
  const [smallRestaurantLogo, setSmallRestaurantLogo] = useState(null);
  //   const dispatch = useDispatch();
  const [restaurant, setRestaurant] = useState({});
  const { t } = useTranslation();
  const receiptRef = useRef();
  const { currency, decimal_preference, notes } = useGetActiveLocation();

  const tabs = [deliverectOrder?.order_type];

  // Get Restaurant info
  useEffect(() => {
    ResturantService.getResturantInfo(
      (res) => {
        setRestaurant(res?.data?.data?.data || {});
        setSmallRestaurantLogo(res?.data?.data?.data?.smallimage);
      },
      (err) => {
        console.log({ err });
      }
    );
  }, []);
  const getTotalTax = () => {
    const orderTax =
      deliverectOrder && deliverectOrder?.tax
        ? Number(deliverectOrder?.tax)
        : 0;

    return numberFormat(orderTax, 3);
  };

  const showChannel =
    deliverectOrder &&
    deliverectOrder?.commission &&
    Number(deliverectOrder?.commission) > 0
      ? true
      : false;
  const deliveryFee =
    deliverectOrder && deliverectOrder?.commission
      ? Number(deliverectOrder?.commission)
      : 0;

  const showDiscount =
    deliverectOrder &&
    deliverectOrder?.discount &&
    Number(deliverectOrder?.discount) > 0
      ? true
      : false;
  const discountValue =
    deliverectOrder && deliverectOrder?.discount
      ? Number(deliverectOrder?.discount)
      : 0;
  const discountType =
    deliverectOrder && deliverectOrder?.discount_type
      ? deliverectOrder?.discount_type
      : "percentage";

  const showTax = deliverectOrder && deliverectOrder?.tax > 0 ? true : false;

  const subTotalPrice = deliverectOrder && Number(deliverectOrder?.order_price);

  // Get channel commission if available
  const getChannelPrice = () => {
    let channelFee = 0;
    if (showChannel) {
      const fee = (deliveryFee * subTotalPrice) / 100;
      channelFee = fee;
    }
    return numberFormat(channelFee, 3);
  };

  // Getting total discount from API result
  const discountPrice = () => {
    let discount = 0.0;
    if (showDiscount && discountType === "percentage") {
      const mainDiscount = (discountValue * subTotalPrice) / 100;
      discount = mainDiscount;
      return discount;
    } else if (
      showDiscount &&
      (discountType === "fixed" || discountType === "amount")
    ) {
      return discountValue;
    } else {
      return discount;
    }
  };

  // Calculating the total price
  const getOrderTotalPrice = () => {
    let totalPrice = 0;
    if (showChannel && showDiscount) {
      totalPrice += subTotalPrice;
      totalPrice += getTotalTax();
      totalPrice += getChannelPrice();
      totalPrice -= discountPrice();
      return numberFormat(totalPrice, 3);
    } else if (!showChannel && showDiscount) {
      totalPrice += subTotalPrice;
      totalPrice += getTotalTax();
      totalPrice -= discountPrice();
      return numberFormat(totalPrice, 3);
    } else if (showChannel && !showDiscount) {
      totalPrice += subTotalPrice;
      totalPrice += getChannelPrice();
      totalPrice += getTotalTax();
      return numberFormat(totalPrice, 3);
    } else if (!showChannel && !showDiscount) {
      totalPrice += subTotalPrice;
      totalPrice += getTotalTax();
      return numberFormat(totalPrice, 3);
    } else {
      totalPrice += subTotalPrice;
      totalPrice += getTotalTax();
      return numberFormat(totalPrice, 3);
    }
  };

  const handlePrient = useReactToPrint({
    content: () => receiptRef.current,
  });

  return (
    <div className={classes.ReceiptPage}>
      <div className={`${classes.CartBox} hide-content`}>
        <div className={classes.Cart}>
          {/* Header */}
          <DelevirectDrawerHeader
            status={deliverectOrder?.status}
            order_no={deliverectOrder?.order_no}
            created_at={deliverectOrder?.created_at}
            logo={deliverectOrder?.delivery_channel?.logo}
            channelName={deliverectOrder?.delivery_channel?.name}
          />
          <DeliverectDrawerTabHeader tabs={tabs} />

          <div className={classes.OrderPersons}>
            {/* <p className={classes.OrderCustomer}>Customer Guest</p> */}
          </div>
          {/* Content */}
          {/* List Item */}
          <div className={classes.PriceList}>
            {deliverectOrder &&
              deliverectOrder?.orderItems.map((item, index) => {
                return (
                  <SingleListItem
                    item={item}
                    key={index}
                    id={item?.item_id}
                    index={index + 1}
                    quantity={item.quantity}
                    price={item?.item?.amount}
                    sizes={item?.item?.sizes}
                    translations={item?.item?.translations}
                    name={item?.item?.name}
                  />
                );
              })}
          </div>
          <br />
          <div className={classes.PaymentTab}>
            <Payment text={t("SUBTOTAL")} price={subTotalPrice} />
            {showTax && <Payment text={t(`Tax `)} price={getTotalTax()} />}

            {showChannel && (
              <Payment text={t(`Channel`)} price={getChannelPrice()} />
            )}
            {/* {checkoutChannel && checkoutChannel?.name && (
              <Payment text={t("Channel")} price={getChannelPrice()} />
            )} */}
            {showDiscount && (
              <>
                {discountType === "percentage" ? (
                  <Payment
                    text={t(`Discount (${discountValue}٪)`)}
                    price={discountPrice()}
                  />
                ) : (
                  <Payment
                    text={t(`Discount (${discountValue})`)}
                    price={discountPrice()}
                  />
                )}
              </>
            )}

            <Payment
              text={t("PAYABLE_AMOUNT")}
              price={getOrderTotalPrice()}
              size="large"
            />
            {/* <Payment
              text={t("PAYABLE_AMOUNT")}
              price={getTotalPrice()}
              size="large"
            /> */}
          </div>
        </div>
      </div>
      {/* Receipt View */}
      <div className={classes.ReceiptBox} id="receipt-to-print">
        <div className={`${classes.ReceiptLanguageSwitcher} hide-content`}>
          <span>Preview</span>
        </div>
        <div className={classes.Receipt} ref={receiptRef}>
          <img
            src={smallRestaurantLogo || logo}
            alt="Company Logo"
            height={50}
            width={50}
          />
          <p className={classes.RestaurantNameLg1}>
            {restaurant?.translations?.en?.name}
          </p>
          <p className={classes.RestaurantNameLg2}>
            {restaurant?.translations?.ar?.name}
          </p>
          {restaurant && restaurant?.tax_no && (
            <p className={classes.VAT}>VAT : {restaurant?.tax_no || ""}</p>
          )}

          <p className={classes.Welcome}>Welcome</p>
          <div className={classes.TaxText}>
            <span className={classes.TaxTextLg1}>Simplified Tax Invoice</span>
            <span className={classes.TaxTextLg2}>فاتورة ضريبية مبسطة</span>
          </div>
          <p className={classes.Id}>
            {deliverectOrder?.order_calling_number || ""} # الطلب
          </p>
          <div className={classes.DateBox}>
            <span className={classes.DateEng}>
              {moment().format("hh:mm:ss a DD/MM/yyyy")}
            </span>
            <span className={classes.DateAr}>فاتورة ضريبية</span>
          </div>
          <div className={classes.NameBox}>
            <span className={classes.NameEng}>
              {deliverectOrder && deliverectOrder?.client_name}
            </span>
            {/* <span className={classes.NameAr}>{order?.client_name}</span> */}
          </div>
          <hr className={classes.LineBreaker} />
          <table className={`${classes.ReceiptTable} receipt-table`}>
            <thead>
              <th>Item Name</th>
              <th>QT</th>
              <th>Price</th>
              <th>VAT</th>
              <th>Subtotal</th>
            </thead>
            <tbody>
              {deliverectOrder?.orderItems?.length > 0 &&
                deliverectOrder?.orderItems?.map((item, index) => {
                  return (
                    <tr>
                      <td className={classes.ItemNameCell}>
                        <span>{item?.item?.name}</span>
                      </td>
                      <td className="text-2xl">{item?.quantity}</td>
                      <td>
                        {Number(item?.item?.price)?.toFixed(decimal_preference)}
                      </td>
                      <td>{item?.vat}</td>
                      <td>
                        {Number(item?.quantity * item?.item?.price)?.toFixed(
                          decimal_preference
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <div className="w-full flex flex-row items-center justify-between pb-3">
            <span className="text-xl lg:text2xl font-Inter text-gray-600">
              Price without Tax
            </span>
            <span className="text-xl lg:text2xl font-Inter text-gray-600">
              {currency} {subTotalPrice.toFixed(decimal_preference)}
              {/* {currency} {getSubTotalPrice()} */}
            </span>
          </div>
          {showTax && (
            <div className="w-full flex flex-row items-center justify-between pb-3">
              <span className="text-xl lg:text2xl font-Inter text-gray-600">
                Tax
              </span>
              <span className="text-xl lg:text2xl font-Inter text-gray-600">
                {currency} {getTotalTax()?.toFixed(decimal_preference)}
              </span>
            </div>
          )}

          {showChannel && (
            <div className="w-full flex flex-row items-center justify-between pb-3">
              <span className="text-xl lg:text2xl font-Inter text-gray-600">
                Channel
              </span>
              <span className="text-xl lg:text2xl font-Inter text-gray-600">
                {currency} {getChannelPrice()?.toFixed(decimal_preference)}
              </span>
            </div>
          )}
          {/* Discount */}
          {showDiscount && (
            <div className="w-full flex flex-row items-center justify-between pb-3">
              <span className="text-xl lg:text2xl font-Merchant text-gray-600">
                Discount
              </span>
              <span className="text-xl lg:text2xl font-Merchant text-gray-600">
                {currency} {discountPrice().toFixed(decimal_preference)}
              </span>
            </div>
          )}
          <div className="w-full flex flex-row items-center justify-between pb-3">
            <span className="text-xl lg:text2xl font-Inter text-gray-600">
              Payable Amount
            </span>
            <span className="text-xl lg:text2xl font-Inter text-gray-600">
              {currency} {getOrderTotalPrice()?.toFixed(decimal_preference)}
            </span>
          </div>
          <div className="w-full mt-10 text-center">
            <p className="text-gray-600 text-center text-2xl">
              {" "}
              {notes?.split(/\r?\n/).map((item, index) => (
                <p key={index} className="text-gray-600 text-center text-2xl">
                  {item}
                </p>
              ))}
            </p>
          </div>
          <br />
          <br />
          <br />
          <br />
          {/* <img src={barCodeImg} alt="Bar Code" className={classes.BarCodeImg} /> */}
        </div>
        <span className="hide-content">
        <IconButton
          icon="print"
          iconPosition="left"
          text="Print Receipt Only"
          customClasses={classes.PrintBtn}
          onClicked={() => handlePrient()}
        />
        </span>
      </div>
    </div>
  );
};

export default LiveOrdersReceipt;
